import ColorTypography from '~/components/ColorTypography'
import Desktop from '~/components/Desktop'
import MouseTooltip from 'react-sticky-mouse-tooltip'
import React from 'react'
import useTooltipContext from '~/hooks/useTooltipContext'

export default () => {
    const { tooltip } = useTooltipContext()

    return (
        <Desktop>
            <MouseTooltip visible={true} offsetX={15} offsetY={10}>
                <ColorTypography variant="h1">{tooltip.toUpperCase()}</ColorTypography>
            </MouseTooltip>
        </Desktop>
    )
}
