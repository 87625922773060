import styled from 'styled-components'

export const ProjectPreviewContainer = styled.div<{ height: string }>`
    position: relative;
    width: 100%;
    height: ${({ height }) => height};
    overflow: hidden;
    cursor: pointer;

    video {
        margin-left: 50%;
        transform: translateX(-50%);
        height: 50vh;
    }
`
