import TooltipContext from '~/contexts/tooltipContext'
import { useContext } from 'react'

export default () => {
    const context = useContext(TooltipContext)

    if (context === undefined) {
        throw new Error('useTooltipContext must be used within a TooltipProvider')
    }

    return context
}
