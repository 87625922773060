import React, { memo } from 'react'

import { Box } from '@material-ui/core'
import ColorTypography from '~/components/ColorTypography'
import { Link } from 'gatsby'
import Mobile from '~/components/Mobile'
import { Project } from '~/types'
import { ProjectPreviewContainer } from './ProjectPreview.style'
import { use100vh } from 'react-div-100vh'
import { useInView } from 'react-intersection-observer'
import { useLocation } from '@reach/router'
import useTooltipContext from '~/hooks/useTooltipContext'

type ViewProps = {
    project: Project
}

export default ({ project }: ViewProps) => {
    const [containerRef, inView, entry] = useInView({ triggerOnce: true })
    const { title, url, teaserVideoFile, teaserVideoPlaceholder } = project
    const { setTooltip } = useTooltipContext()
    const { search: locationSearch, pathname: locationPathname } = useLocation()
    const height = use100vh()
    const halfHeight = height ? `${Math.round(height / 2)}px` : '50vh'

    return (
        <Link
            to={`/projects/${url.current}`}
            state={{ prevPath: locationPathname + locationSearch }}
        >
            <ProjectPreviewContainer
                ref={containerRef}
                onMouseEnter={() => setTooltip(title)}
                onMouseLeave={() => setTooltip('')}
                height={halfHeight}
            >
                <Mobile>
                    <Box position="absolute" width="100%" height="100%" zIndex={500}>
                        <Box
                            display="flex"
                            width="100%"
                            height="100%"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <ColorTypography variant="h1" align="center">
                                {title.toUpperCase()}
                            </ColorTypography>
                        </Box>
                    </Box>
                </Mobile>
                <video muted autoPlay loop playsInline poster={teaserVideoPlaceholder?.asset.url}>
                    {inView && <source src={teaserVideoFile.asset.url} type="video/mp4" />}
                </video>
            </ProjectPreviewContainer>
        </Link>
    )
}
